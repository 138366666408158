<template>
    <div>
        <Layout>
            <div class="row form__common--center">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="mb-0 title--screen">フォーム検索</h3>
                        </div>
                        <div class="card-body">
                            <div class="row justify-content-center">
                                <div class="col-sm-6">
                                    <form class="needs-validation" autocomplete="off">
                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">{{ $t('managerForm.list.nameCampaign') }}</label>
                                            <div class="col-sm-9">
                                                <Multiselect
                                                    :id="`select-campaign-default`"
                                                    :value.sync="formSearch.campaign"
                                                    :options="listDataCampaign"
                                                    :config="configForm.campaign"
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">{{ $t('managerForm.list.nameForm') }}</label>
                                            <div class="col-sm-9">
                                                <Multiselect
                                                    :id="`select-nameForm-default`"
                                                    :value.sync="formSearch.name"
                                                    :options="listDataNameForm"
                                                    :config="configForm.nameForm"
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">{{ $t('managerForm.list.searhTag') }}</label>
                                            <div class="col-sm-9">
                                                <Multiselect
                                                    :id="`select-searhTag-default`"
                                                    :value.sync="formSearch.tag"
                                                    :options="OPTIONS_TAG"
                                                    :config="{ trackBy: 'id', label: 'value', isRemove: false, error: false }"
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">{{ $t('managerForm.list.status') }}</label>
                                            <div class="col-sm-9">
                                                <div class="d-flex flex-wrap">
                                                    <div v-for="item in STATUS_FORM" :key="item.id + 'sss'">
                                                        <InputRadio
                                                            class="mr-3 mb-2"
                                                            :model.sync="formSearch.status"
                                                            :id="`raido-statusForm_${item.id}`"
                                                            :name="`raido-statusForm`"
                                                            :value="item.id"
                                                            :label="item.value"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">{{ $t('managerForm.list.startDateCP') }}</label>
                                            <div class="col-sm-9">
                                                <div class="d-flex align-items-center mb-2">
                                                    <DateNormal :full_date.sync="formSearch.date_from.campaign_start_date"> </DateNormal>
                                                    から
                                                </div>
                                                <div class="d-flex align-items-center mb-2">
                                                    <DateNormal
                                                        :full_date.sync="formSearch.date_to.campaign_start_date"
                                                        :config="{
                                                            error: false
                                                        }"
                                                    >
                                                    </DateNormal>
                                                    まで
                                                </div>
                                                <small>※年、年月のみの入力でも検索可能です。</small>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">{{ $t('managerForm.list.endDateCP') }}</label>
                                            <div class="col-sm-9">
                                                <div class="d-flex align-items-center mb-2">
                                                    <DateNormal
                                                        :full_date.sync="formSearch.date_from.campaign_end_date"
                                                        :config="{
                                                            error: false
                                                        }"
                                                    >
                                                    </DateNormal>
                                                    から
                                                </div>
                                                <div class="d-flex align-items-center mb-2">
                                                    <DateNormal
                                                        :full_date.sync="formSearch.date_to.campaign_end_date"
                                                        :config="{
                                                            error: false
                                                        }"
                                                    >
                                                    </DateNormal>
                                                    まで
                                                </div>
                                                <small>※年、年月のみの入力でも検索可能です。</small>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">{{ $t('managerForm.list.endDateSecretariat') }}</label>
                                            <div class="col-sm-9">
                                                <div class="d-flex align-items-center mb-2">
                                                    <DateNormal
                                                        :full_date.sync="formSearch.date_from.secretariat_end_date"
                                                        :config="{
                                                            error: false
                                                        }"
                                                    >
                                                    </DateNormal>
                                                    から
                                                </div>
                                                <div class="d-flex align-items-center mb-2">
                                                    <DateNormal
                                                        :full_date.sync="formSearch.date_to.secretariat_end_date"
                                                        :config="{
                                                            error: false
                                                        }"
                                                    >
                                                    </DateNormal>
                                                    まで
                                                </div>
                                                <small>※年、年月のみの入力でも検索可能です。</small>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">{{ $t('managerForm.list.client') }}</label>
                                            <div class="col-sm-9">
                                                <Multiselect
                                                    :id="`select-client-default`"
                                                    :value.sync="formSearch.client"
                                                    :options="listDataClient"
                                                    :config="configForm.client"
                                                />
                                            </div>
                                        </div>

                                        <div class="text-center mt-4 position-relative">
                                            <button type="button" @click="clear()" class="btn btn-light">クリア</button>
                                            <button type="button" @click="search()" class="btn btn-primary ml-3">検索</button>
                                        </div>
                                    </form>

                                    <div class="form-group row mt-4">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('managerForm.list.bookmark') }}</label>
                                        <div class="col-sm-9">
                                            <!--  -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <h3 class="mb-0 title--screen">
                                キャンペーン検索結果 <span>件数（{{ configPage.total }})</span>
                            </h3>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="d-flex align-items-center input-group-sm" style="white-space: nowrap">
                                        検索条件：
                                        <input type="text" class="form-control ml-3" v-model="bookmarkName" style="width: 200px" />
                                        <button class="btn btn-sm btn-primary ml-2" @click="addBookMark()">{{ $t('btn.save') }}</button>
                                    </div>
                                </div>
                            </div>

                            <TableListComponent
                                :config="configTable"
                                :dataList="listDataTable"
                                :isLoading="isLoading"
                                :configPage.sync="configPage"
                                :stickyAction="true"
                                @onChangePage="getList()"
                            >
                                <template #row="{ row, index }">
                                    <tr @dblclick="handleGoTo('/manager-form/view', row)">
                                        <td>
                                            {{ index }}
                                        </td>
                                        <td>
                                            <div class="row-data-small">
                                                {{ row?.campaign?.name }}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="row-data-medium" v-b-tooltip.hover :title="row.name">
                                                {{ row?.name ?? '' }}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="row-data-small">
                                                {{ row?.url_public ?? '' }}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="row-data-small">
                                                {{ handleViewStatus(row?.status) ?? '' }}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="row-data-medium">
                                                <!--  -->
                                            </div>
                                        </td>
                                        <td class="sticky_action">
                                            <div class="row-data-small">
                                                <a
                                                    href="javascript:void(0)"
                                                    class="text-info action__record"
                                                    @click="handleGoTo('/manager-form/view', row)"
                                                    >{{ $t('btn.detail') }}</a
                                                >
                                                <a href="javascript:void(0)" class="text-success" @click="handleGoTo('/manager-form/edit', row)">{{
                                                    $t('btn.edit')
                                                }}</a>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </TableListComponent>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
        <Footer />
    </div>
</template>

<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import Footer from '@/components/Footer/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';
import DateNormal from '@/components/DateCustoms/DateNormal/main.vue';
import TableListComponent from '@/components/TableList/main.vue';

import { configs, STATUS_FORM, OPTIONS_TAG, initFormSearch } from './constants';
import { handleStatus } from './handlers';
import { InputRadio } from '@/components/Input';
import { managerFormMethods, masterMethods } from '@/state/helpers';

export default {
    name: 'ManagerFormList',
    page: {
        title: 'キャンペーン一覧',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Layout,
        Footer,
        Multiselect,
        InputRadio,
        DateNormal,
        TableListComponent
    },
    data() {
        return {
            configForm: configs,
            configTable: configs.configTable,
            configPage: configs.configPage,
            STATUS_FORM: STATUS_FORM,
            OPTIONS_TAG: OPTIONS_TAG,
            formSearch: initFormSearch(),
            listDataTable: [],
            listDataCampaign: [],
            listDataNameForm: [],
            listDataClient: [],
            bookmarkName: '',
            isLoading: false
        };
    },
    methods: {
        ...managerFormMethods,
        ...masterMethods,

        handleGoTo(url, row) {
            this.$router.push({ path: url, query: { id: row.id } });
        },

        addBookMark() {},
        clear() {
            this.formSearch = initFormSearch();
            this.getList();
        },
        search() {
            this.getList();
        },

        handleViewStatus(status) {
            return handleStatus(status);
        },

        getQueryString() {
            let query = [`page=${this.configPage.page}`, `limit=${this.configPage.limit}`, `sorts[id]=desc`];
            let copyForm = Object.assign({}, this.formSearch);

            if (copyForm) {
                if (copyForm.campaign !== null) copyForm.campaign_name = copyForm.campaign?.name;
                if (copyForm.client !== null) copyForm.client_name = copyForm.client?.name;
                if (copyForm.name !== null) copyForm.name = copyForm.name?.name;
                if (copyForm.tag !== null) copyForm.tag = copyForm.tag?.id;
                // if (copyForm.status.length > 1) copyForm.status = copyForm.status.join(',');

                if (copyForm.date_from) {
                    for (const [key, value] of Object.entries(copyForm.date_from)) {
                        if (key && value) query.push(`from[${key}]=${value}`);
                    }
                }
                if (copyForm.date_to) {
                    for (const [key, value] of Object.entries(copyForm.date_to)) {
                        if (key && value) query.push(`to[${key}]=${value}`);
                    }
                }

                for (const [key, value] of Object.entries(copyForm)) {
                    if (key !== 'date_from' && key !== 'date_to' && key !== 'campaign' && key !== 'client') {
                        if (key && value) query.push(`search[${key}]=${value}`);
                    }
                }
            }
            if (this.has) {
                for (const [key, value] of Object.entries(this.has)) {
                    if (key && value) query.push(`has[${key}]=${encodeURIComponent(value)}`);
                }
            }
            return query.join('&');
        },

        async getList() {
            let query = this.getQueryString();
            this.isLoading = true;
            try {
                const data = await this.getListManagerForm(query);
                this.listDataTable = data.data;
                this.configPage.total = data._metadata.total;
                this.configPage.limit = data._metadata.limit * 1;
                if (!this.listDataTable.length && this.configPage.page !== 1) {
                    this.configPage.page = 1;
                    this.getList();
                } else {
                    this.isLoading = false;
                }
            } catch (error) {
                console.log('error', error);
            }
        },

        async getMasterCampaign() {
            this.isLoading = true;
            try {
                const dataRes = await this.listMaster('all/campaigns');
                this.listDataCampaign = dataRes;
            } catch (error) {
                console.log('error', error);
            } finally {
                this.isLoading = false;
            }
        },

        async getListMasterClient() {
            try {
                const dataRespone = await this.listMaster('all/clients');
                this.listDataClient = dataRespone;
            } catch (error) {
                console.log('error', error);
            }
        },

        async getListMasterForm() {
            try {
                let query = `page=1&limit=9999&sorts[id]=desc`;
                const data = await this.getListManagerForm(query);
                this.listDataNameForm = data.data;
            } catch (error) {
                console.log('error', error);
            }
        }
    },

    mounted() {
        this.getList();
        this.getMasterCampaign();
        this.getListMasterClient();
        this.getListMasterForm();
    }
};
</script>

<style lang="scss" scoped></style>
